import React from 'react'
import { Title, Summary, AboutMe } from './'

function Main() {
  return (
    <>
        <Title></Title>
        <AboutMe/>
        <Summary/>
    </>
  )
}

export default Main
import React from 'react'
import { Education, Experience } from './Summary/'

const SUMMARY_TEXT = ""
function Summary() {
    return (
        <div id="Summary">
            <Education/>
            <Experience/>
        </div>
    )
}

export default Summary